import { ThemeProvider as ThemeProvider2 } from '@hexa-ui/components2';
import { ThemeProvider, ThemeProviderType } from '@hexa-ui/theme';
import {
  DocumentTitle,
  PropsPerClusterProvider,
  createFeatureToggleServiceV2,
  useAppHeader,
  useHubTheme
} from 'admin-portal-shared-services';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';
import IntlProvider from './i18n/provider';
import { FileProvider } from './routes/FileMultiUploaderExamplePage/FileContext';
import AppBarConfigProvider from './utils/AppBarConfigProvider';
import { EnvProvider, VendorIdFromEnv } from './utils/envProvider';
import { SetThemeProvider } from './utils/setThemeProvider';

type Props = {
  segmentKey: string;
  vendorId: VendorIdFromEnv;
};

const NEW_ROUTES = ['/get-started', '/technical-documentation', '/design-documentation'];

function App(props: Props): JSX.Element {
  const [hubTheme, setHubTheme] = useState<ThemeProviderType['theme']>('customer');
  useHubTheme(hubTheme);

  const [appHeader, setAppHeaderConfig] = useAppHeader();
  const currentRoute = window.location.pathname;

  useEffect(() => {
    if (appHeader?.selectedVendor) {
      localStorage.setItem('defaultVendorSelected', appHeader?.selectedVendor);
    }
  }, [appHeader?.selectedVendor]);

  useEffect(() => {
    if (!NEW_ROUTES.includes(currentRoute)) {
      const defaultVendorSelected = localStorage.getItem('defaultVendorSelected') ?? undefined;
      setAppHeaderConfig({
        countryOptions: ['BR', 'MX', 'AR', 'US', 'ES'],
        countrySelect: true,
        vendorSelect: true,
        defaultVendor: defaultVendorSelected
      });
    }
    createFeatureToggleServiceV2('admin-portal-demo-mfe', '2haJLxsNaavmrMAFAJQHw');
  }, [setAppHeaderConfig, currentRoute]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <EnvProvider env={props}>
        <PropsPerClusterProvider propValues={props}>
          <ThemeProvider theme={hubTheme}>
            <SetThemeProvider setTheme={setHubTheme}>
              <ThemeProvider2>
                <FileProvider>
                  <DocumentTitle>Overview</DocumentTitle>
                  <IntlProvider>
                    <AppBarConfigProvider />
                  </IntlProvider>
                </FileProvider>
              </ThemeProvider2>
            </SetThemeProvider>
          </ThemeProvider>
        </PropsPerClusterProvider>
      </EnvProvider>
    </ErrorBoundary>
  );
}

export default App;
